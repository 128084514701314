import React, { useState } from 'react';
import { Link } from 'gatsby';
import PageHead from '../components/PageHead';
import Layout from '../components/Layout';

export function Head({ location }) {
  return <PageHead title="Page not found!" location={location} />;
}

export default function NotFoundPage({ location }) {
  const [activeTheme, setActiveTheme] = useState(() => {
    if (typeof localStorage !== 'undefined') {
      return localStorage.getItem('themeRekh');
    }
    return null;
  });
  const [dummyState, setDummyState] = useState(false); // to trigger loco refresh when theme is changed

  return (
    <Layout
      location={location}
      theme={activeTheme}
      setTheme={setActiveTheme}
      dummyState={dummyState}
      setDummyState={setDummyState}
    >
      <section className="success-page">
        {activeTheme === 'theme1' && (
          <div className="hero t1-404-hero">
            <div className="wrapper col-grid">
              <div className="empty" />
              <div className="hero-content">
                <h1 className="split-n-wrap">Oh, no!</h1>
                <p className="subhead para big split-n-wrap">
                  The page you are looking for doesn't exist 😞.
                </p>
                <Link to="/" className="link">
                  Return to homepage
                </Link>
              </div>
            </div>
          </div>
        )}

        {activeTheme === 'theme2' && (
          <div className="hero t2-404-hero">
            <div className="wrapper">
              <div className="heading">
                <h1 className="split-n-wrap">Oh, no!</h1>
                <p className="subhead para big split-n-wrap">
                  The page you are looking for doesn't exist 😞.
                </p>
                <Link to="/" className="link">
                  Return to homepage
                </Link>
              </div>
            </div>
          </div>
        )}

        {activeTheme === 'theme3' && (
          <div className="hero t3-404-hero">
            <div className="heading">
              <div className="wrapper">
                <h1 className="show-up">Oh, no!</h1>
                <p className="para big subhead split-n-wrap">
                  The page you are looking for doesn't exist 😞.
                </p>
                <Link to="/" className="link">
                  Return to homepage
                </Link>
              </div>
            </div>
          </div>
        )}
      </section>
    </Layout>
  );
}
